shop.discount =
    {
        addToCart: function () {
            $('body').on('click', '#discount-code-apply', function () {
                var discountError = $("#discount-error"),
                    code = $('#discount-code-input').val(),
                    paymentIntentId = $('#payment-methods-wrapper').attr('data-payment-intent-id');

                $.ajax(
                    {
                        url: '/discounts',
                        type: 'POST',
                        data: {
                            'code': code,
                            'payment_intent_id': paymentIntentId
                        },
                        dataType: 'json',
                        success: function (data) {
                            $('#total').html(data.total);
                            $('#checkout-discounts').html(data.discounts);
                            $('#payment-methods-wrapper').attr('data-amount', data.singularized_total);
                            window.paymentRequestUpdate = true;
                            shop.base.addCustomFlash("#delivery-information .col-lg-12", 'success', window.checkoutMessages.addedDiscount);
                            shop.base.scrollTop('#checkout-wrapper');
                            discountError.hide();
                        },
                        error: function (xhr, evt, status) {
                            discountError.text($.parseJSON(xhr.responseText).errors[0]).show();
                            discountError.show();
                        }
                    });
                return false;
            });
        }
    }