shop.review =
    {
        add: function () {
            $('body').on('click', '#review-product', function () {
                var $this = $(this),
                    productId = $this.attr('data-product-id');

                $('.product-review-flash').remove();

                $.ajax(
                    {
                        url: '/products/' + productId + '/reviews/new',
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#product-review-modal').html(data.modal);
                            shop.base.openModal('#product-review-form');
                            var $rateYo = $("#star-rating-animation").rateYo(
                                {
                                    fullStar: true,
                                    rating: 5
                                });
                            $rateYo.on("rateyo.set", function (e, data) {
                                document.getElementById("review_rating").value = data.rating;
                            });
                        },
                        error: function (xhr, evt, status) {
                            shop.base.addCustomFlash(".product-wrapper", 'danger product-review-flash', 'Unable to add a review at the moment, please try again later');
                            shop.base.scrollTop('.navbar-container');
                        }
                    });
                return false;
            });
        },

        create: function () {
            $('body').on('submit', '#create-product-review', function () {
                var $this = $(this),
                    url = $this.attr('action');

                $('.product-review-flash').remove();

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#product-review-form').modal('hide');
                            $('#review-product').remove();
                            shop.base.addCustomFlash(".product-wrapper", 'success product-review-flash', 'Successfully submitted your review for approval.');
                            shop.base.scrollTop('.navbar-container');
                        },
                        error: function (xhr, evt, status) {
                            shop.base.jsonErrors(xhr, evt, status, $this);
                        }
                    });
                return false;
            });
        },
    }