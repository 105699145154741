shop.landing_page =
    {
        incrementQuantity: function () {
            $elem = $('#cart_item_quantity');
            newValue = Number($elem.val()) + 1;
            $elem.val(newValue);
        },

        decreaseQuantity: function () {
            $elem = $('#cart_item_quantity');
            newValue = Number($elem.val()) - 1;
            if (newValue < 1) {
                $elem.val(1);
            } else {
                $elem.val(newValue);
            }
        },

        changeVariant: function () {
            $('body').on('change', '.variant-dropdown', function () {
                var productId = $(this).attr('data-product-id'),
                    variantId = $(this).val(),
                    shopUrl = $('#landing-page-wrapper').attr('data-shop-url').split('?')[0];
                $.ajax(
                    {
                        url: 'https://' + shopUrl + '/products/' + productId + '/variants/' + variantId,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('.product-price').text(data.variant_price);
                            if(data.product_image_id !== null)
                            {
                                var index = $('.product-carousel-' + productId + ' .carousel-item[data-image-id="' + data.product_image_id + '"]').attr('data-index');
                                $('.product-carousel-' + productId).carousel(Number(index));
                            }
                        }
                    });
            });
        },

        initCarousel: function () {
            $('#productCarousel').carousel({
                interval: false,
            });
        },

        cartDropdown: function() {
            $(window).on("scroll", function() {
                var y_scroll_pos = window.pageYOffset;

                var extrainfo = document.getElementById("landing-page-navbar");

                if (typeof extrainfo != "undefined" && extrainfo != null) {
                    var scroll_pos_test = $("#landing-page-navbar").offset().top;
                } else {
                    var scroll_pos_test = 500; // set to whatever you want it to be
                }

                if (y_scroll_pos > scroll_pos_test) {
                    $("#landing-page-navbar").show();
                } else {
                    $("#landing-page-navbar").hide();
                }
            });
        },
    }