shop.base = {
    jsonErrors: function (xhr, evt, status, form) {
        var content, value, _i, _len, _ref, $this, contentWrapper;
        $this = form;
        contentWrapper = $this.find('.json-error-wrapper');
        content = contentWrapper.find('#errors');
        content.find('ul').empty();
        _ref = $.parseJSON(xhr.responseText).errors;
        // Append errors to list on page
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            value = _ref[_i];
            content.show().find('ul').append('<li><i class="fa fa-times-circle"></i>' + value + '</li>');
        }
        contentWrapper.show();
    },

    enableCloseTabPrompt: function () {
        window.onbeforeunload = function (e) {
            e = e || window.event;

            // For IE and Firefox prior to version 4
            if (e) {
                e.returnValue = 'Have you forgotten something? Your order is waiting for you!';
            }

            // For Safari
            return 'Have you forgotten something? Your order is waiting for you!';
        }
    },

    disableCloseTabPrompt: function () {
        window.onbeforeunload = null;
    },

    initSocialShare: function () {
        $.fn.tdSocialSharer = function (a) {
            var b;
            return b = $.extend({
                popUpWidth: 550,
                popUpHeight: 450,
                popUpTop: 100,
                useCurrentLocation: !1
            }, a), this.each(function () {
                return $(this).bind("click", function (a) {
                    var c,
                        d,
                        e,
                        f,
                        g,
                        h,
                        i,
                        j,
                        k,
                        l,
                        m,
                        n;
                    switch (a.preventDefault(), g = $(this).data("social"), n = b.popUpWidth, c = b.popUpHeight, e = screen.height, f = screen.width, d = Math.round(f / 2 - n / 2), k = b.popUpTop, l = void 0, m = b.useCurrentLocation, j = m ? window.location : encodeURIComponent(g.url), i = g.text, h = encodeURIComponent(g.image), g.type) {
                        case "facebook":
                            l = "http://www.facebook.com/sharer.php?u=" + j + "&t=" + i;
                            break;
                        case "twitter":
                            l = "http://twitter.com/share?url=" + j + "&text=" + i;
                            break;
                    }
                    return window.open(l, "", "left=" + d + " , top=" + k + ", width=" + n + ", height=" + c + ", personalbar=0, toolbar=0, scrollbars=1, resizable=1")
                })
            })
        }
        $(".social-share a:not(#email)").tdSocialSharer();
    },

    scrollTop: function (element) {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(element).offset().top
        }, 1000);
    },

    inputHasValue: function (element) {
        return element.length > 0 && element.val() !== '' ? true : false
    },

    addCustomFlash: function (element, alertClass, message) {
        $(element).prepend('<div class="mt-4 alert alert-' + alertClass + '" role="alert">' + message + '</div>');
    },

    checkoutGaUrl: function () {
        var completeUrl = $('#checkout-section').attr('data-complete-checkout-url'),
            ga = window[window['GoogleAnalyticsObject']],
            tracker;
        if (ga && typeof ga.getAll === 'function') {
            tracker = ga.getAll()[0];
            completeUrl = (new window.gaplugins.Linker(tracker)).decorate(completeUrl);
        }
        return completeUrl;
    },

    changeLocale: function () {
        $('#country-selector').on('change', function () {
            window.location = shop.base.updateQueryStringParameter(window.location.href, 'locale', this.value);
        });
    },

    updateQueryStringParameter: function (uri, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        } else {
            return uri + separator + key + "=" + value;
        }
    },

    initCookies: function () {
        if (localStorage.getItem('cookieToastState') != 'shown') {
            $('#cookieToast').toast('show');
            localStorage.setItem('cookieToastState', 'shown')
        }
    },

    openModal: function (target) {
        $(target).modal(
            {
                backdrop: 'static',
                keyboard: false
            });
    },

    getUrlVars: function () {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        return vars;
    },

    initDiscountPopup: function(discountId)
    {
        if (localStorage.getItem('popupDiscountState_' + discountId) != 'shown') {
            timeout = setTimeout(function () {
                $('#newsletter-modal').modal('show');
                localStorage.setItem('popupDiscountState_' + discountId, 'shown');
                clearTimeout(timeout);
            }, 15000);
        }
    },

    initCustomerGiftPopup: function()
    {
        if (localStorage.getItem('popupCustomerGift') != 'shown') {
            timeout = setTimeout(function () {
                $('#customer-gift-modal').modal('show');
                localStorage.setItem('popupCustomerGift', 'shown');
                clearTimeout(timeout);
            }, 15000);
        }
    },

    sendNewsletterEmail: function()
    {
        $('body').on('submit', '#send-newsletter-email', function () {
            var $this = $(this),
                url = $this.attr('action');

            $.ajax(
                {
                    url: url,
                    type: 'POST',
                    data: $this.serialize(),
                    dataType: 'json',
                    success: function (data) {
                        $('#send-newsletter-email').hide();
                        $('#newsletter-generated-code').text(data.discount.code);
                        $('#newsletter-code').show();
                    },
                    error: function (xhr, evt, status) {
                        shop.base.jsonErrors(xhr, evt, status, $this);
                    }
                });
            return false;
        });
    },

    selectCheckboxLabel: function()
    {
        $('body').on('click', '.form-check-label', function()
        {
           $(this).parent().find('.form-check-input').click();
        });
    }
}