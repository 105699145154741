shop.checkout = {

    triggerConfirmEmail: function () {
        if (window.confirmEmailActive) {
            $('#confirm-email-modal').modal(
                {
                    backdrop: 'static',
                    keyboard: false
                });
        }
    },

    confirmEmail: function () {
        $('body').on('submit', '#confirm-email-form', function () {
            var $this = $(this);

            $.ajax(
                {
                    url: '/carts/confirm_email',
                    type: 'POST',
                    data: $this.serialize(),
                    dataType: 'json',
                    success: function (data) {
                        $('#confirm-email-modal').modal('hide');
                        $('#user_email').val(data.email);
                    },
                    error: function (xhr, evt, status) {
                        shop.base.jsonErrors(xhr, evt, status, $this);
                    }
                });
            return false;
        });
    },

    duplicateAddress: function () {
        $('.use-delivery-address').change(function () {
            if (this.checked) {
                $('.copy-delivery').each(function () {
                    var fieldValue = $(this).val(),
                        fieldAttribute = $(this).attr('data-field-name');
                    return $('[data-field-name="billing-' + fieldAttribute + '"]').val(fieldValue);
                });
            } else {
                return $('input[data-field-name*="billing-"]').val('');
            }
            shop.checkout.triggerCountryAlpha2($('#billing_address_country_id').val());
        });
    },

    validShippingAddress: function () {
        window.setInterval(function () {
            if (shop.base.inputHasValue($('#user_email')) && shop.base.inputHasValue($('#delivery_address_first_name')) &&
                shop.base.inputHasValue($('#delivery_address_last_name')) && shop.base.inputHasValue($('#delivery_address_address')) &&
                shop.base.inputHasValue($('#delivery_address_city')) && shop.base.inputHasValue($('#delivery_address_zip')) &&
                shop.base.inputHasValue($('#delivery_address_country_id')) && $('#checkoutTerms').is(':checked') && shop.checkout.hasPaymentMethod()) {
                $('#complete-purchase-button').removeAttr('disabled');
            } else {
                $('#complete-purchase-button').attr('disabled', 'true');
            }
        }, 1000);
    },

    loadPaymentRequest: function (paymentRequest) {
        $('body').on('click', '#complete-purchase-button', function () {
            if (window.cardPayment === false) {
                paymentRequest.show();
                return false;
            }
        });
    },

    selectPaymentMethod: function () {
        window.cardPayment = true;
        $('body').on('click', '#payment-methods-wrapper div', function () {
            var paymentMethod = $(this).attr('id');
            $('#payment-methods-wrapper div').removeClass('active');
            $(this).addClass('active');
            if (paymentMethod === 'credit-card-button') {
                $('#order_payment_type').val('credit_card');
                window.cardPayment = true;
                $('#card-payment-wrapper').show();
                $('#card-payment-wrapper input, #card-payment-wrapper select').prop('disabled', false);
            } else if (paymentMethod === 'apple-request-button') {
                $('#order_payment_type').val('apple_pay');
                window.cardPayment = false;
                $('#card-payment-wrapper').hide();
                $('#card-payment-wrapper input, #card-payment-wrapper select').prop('disabled', true);
            } else if (paymentMethod === 'google-request-button') {
                $('#order_payment_type').val('google_pay');
                window.cardPayment = false;
                $('#card-payment-wrapper').hide();
                $('#card-payment-wrapper input, #card-payment-wrapper select').prop('disabled', true);
            }
        });
    },

    hasPaymentMethod: function () {
        return $('#payment-methods-wrapper div.active').length >= 1 ? true : false;
    },

    validateReferralCode: function () {
        $('body').on('click', '#referral-code-apply', function () {
            var referralCode = $('#referral-code-input').val();
            $('#referral-code-error').text('').hide();
            $('#referral-code-apply').attr('disabled', true);
            $.ajax(
                {
                    url: '/referrals/' + referralCode + '/valid_code',
                    type: 'GET',
                    dataType: 'json',
                    success: function (data) {
                        shop.base.addCustomFlash("#delivery-information .col-lg-12", 'success', window.checkoutMessages.addedReferral);
                        shop.base.scrollTop('#checkout-wrapper');
                        $('#summary-referral-point span').text(referralCode);
                        $('#summary-referral-point').addClass('d-flex').show();
                        $('#referral-code-apply').attr('disabled', false);
                        $('#referral-code-input').val('');
                    },
                    error: function (xhr, evt, status) {
                        $('#referral-code-error').text('Invalid referral code').show();
                        $('#referral-code-apply').attr('disabled', false);
                    }
                });
            return false;
        });
    },

    addOrderBumpToCart: function () {
        $('body').on('click', '.add-order-bump', function () {
            var orderBumpId = $(this).attr('data-order-bump-id'),
                variantId = $('.bump-variant-dropdown[data-order-bump-id="' + orderBumpId + '"]').val();

            $('#updating-cart-modal').modal('show');
            $('.order-bump-flash').remove();

            $.ajax(
                {
                    url: '/order_bumps/' + orderBumpId + '/add_to_cart?variant_id=' + variantId,
                    type: 'POST',
                    dataType: 'json',
                    success: function (data) {
                        shop.checkout.updateCheckoutSummary(data);
                        $("#order-summary-cart-items").prepend(data.order_bump_cart_item);
                        $('.add-order-bump[data-order-bump-id="' + orderBumpId + '"]').hide().attr('disabled', true);
                        $('.remove-order-bump[data-order-bump-id="' + orderBumpId + '"]').show().attr('disabled', false);
                        shop.base.addCustomFlash("#delivery-information .col-lg-12", 'success order-bump-flash', window.checkoutMessages.addedOrderBump);
                        $('#updating-cart-modal').modal('hide');
                    },
                    error: function (xhr, evt, status) {
                        shop.base.addCustomFlash("#delivery-information .col-lg-12", 'danger order-bump-flash', window.checkoutMessages.failedAddedOrderBump);
                        $('#updating-cart-modal').modal('hide');
                    }
                });
            return false;
        });
    },

    removeOrderBumpToCart: function () {
        $('body').on('click', '.remove-order-bump', function () {
            var orderBumpId = $(this).attr('data-order-bump-id');

            $('#updating-cart-modal').modal('show');
            $('.order-bump-flash').remove();

            $.ajax(
                {
                    url: '/order_bumps/' + orderBumpId + '/remove_from_cart',
                    type: 'DELETE',
                    dataType: 'json',
                    success: function (data) {
                        shop.checkout.updateCheckoutSummary(data);
                        $('#order-summary-cart-items li[data-order-bump-id="' + orderBumpId + '"]').remove();
                        $('.remove-order-bump[data-order-bump-id="' + orderBumpId + '"]').hide().attr('disabled', true);
                        $('.add-order-bump[data-order-bump-id="' + orderBumpId + '"]').show().attr('disabled', false);
                        shop.base.addCustomFlash("#delivery-information .col-lg-12", 'success order-bump-flash', window.checkoutMessages.removedOrderBump);
                        $('#updating-cart-modal').modal('hide');
                    },
                    error: function (xhr, evt, status) {
                        shop.base.addCustomFlash("#delivery-information .col-lg-12", 'danger order-bump-flash', window.checkoutMessages.failedRemovedOrderBump);
                        $('#updating-cart-modal').modal('hide');
                    }
                });
            return false;
        });
    },

    changeOrderBumpVariant: function () {

        $('body').on('change', '.bump-variant-dropdown', function () {
            var productId = $(this).attr('data-product-id'),
                variantId = $(this).val(),
                overridePrice = $(this).attr('data-override-price'),
                orderBumpId = $(this).attr('data-order-bump-id');

            if (overridePrice !== "true") {
                $.ajax(
                    {
                        url: '/products/' + productId + '/variants/' + variantId,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('.price[data-order-bump-id="' + orderBumpId + '"]').text(data.variant_price + ' x1');
                        }
                    });
            }
        });
    },

    updateCheckoutSummary: function (data) {
        $('#delivery').html(data.delivery);
        $('#total').html(data.total);
        $('#checkout-discounts').html(data.discounts);
    },

    updateCountryAlpha2: function () {
        $('#billing_address_country_id').change(function () {
            shop.checkout.triggerCountryAlpha2($(this).val());
        });
    },

    triggerCountryAlpha2: function (val) {
        $('#complete-purchase-button').attr('disabled', true);
        $.ajax(
            {
                url: '/countries/' + val,
                type: 'GET',
                dataType: 'json',
                success: function (data) {
                    $('#country_alpha_2').val(data.alpha_2);
                    $('#complete-purchase-button').attr('disabled', false);
                },
                error: function (xhr, evt, status) {
                    $('#complete-purchase-button').attr('disabled', false);
                }
            });
    },

    initCountdown: function (element) {
        "undefined" != typeof b && clearInterval(b);
        var b = setInterval(function () {
            var elem = $(element),
                endTime = elem.attr('data-end-at');

            const minutesSpan = elem.find('.minutes');
            const secondsSpan = elem.find('.seconds');

            var t = shop.checkout.getTimeRemaining(endTime);

            if (t.total <= 0) {
                clearInterval(b);
                $(element).parent().html('Your item is in high demand. Order reservation ended.')
            }

            minutesSpan.html(('0' + t.minutes).slice(-2));
            secondsSpan.html(('0' + t.seconds).slice(-2));
        }, 1e3);
    },

    getTimeRemaining: function (endTime) {
        const total = Date.parse(endTime) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));

        return {
            total,
            days,
            hours,
            minutes,
            seconds
        }
    }
}