$(document).ready(function () {
    var $formElem = $('#process_order');
    if ($formElem.length > 0) {
        var stripe = Stripe($('meta[name="stripe-key"]').attr('content'));
        var elements = stripe.elements();

        // CARD ELEMENTS
        var style = {
            base: {
                color: '#32325d',
                lineHeight: '24px',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };
        var card = elements.create('card', {style: style, hidePostalCode: true});
        card.mount('#card-element');
        card.addEventListener('change', function (event) {
            if (event.error) {
                $('#card-errors').text(event.error.message);
            } else {
                $('#card-errors').text('')
            }
        });
        // END OF CARD ELEMENTS

        // PAYMENT REQUEST WITH APPLE AND ANDROID PAY
        var currentLocale = $('meta[name="current-formatted-locale"]').attr('content'),
            currentCurrency = $('meta[name="current-currency"]').attr('content');
        var paymentRequest = stripe.paymentRequest({
            country: currentLocale,
            currency: currentCurrency,
            total: {
                label: 'Protecify',
                amount: Number($('#payment-methods-wrapper').attr('data-amount')),
            },
            requestPayerName: true
        });

        window.paymentRequestUpdate = false;
        var pollingRequestUpdateInterval = window.setInterval(function () {
            if (window.paymentRequestUpdate === true) {
                paymentRequest.update({
                    total: {
                        label: 'Protecify',
                        amount: Number($('#payment-methods-wrapper').attr('data-amount'))
                    }
                });
                window.clearInterval(pollingRequestUpdateInterval);
                window.paymentRequestUpdate = false;
            }
        }, 500);

        makePaymentRequest(paymentRequest);
        // END OF PAYMENT REQUEST

        $('body').on('submit', '#process_order', function () {
            $('#checkout-validation-warning').remove();
            $('button[type=submit]').attr('disabled', true);
            var billing_details = {
                name: document.getElementById('billing_address_first_name').value + ' ' + document.getElementById('billing_address_last_name').value,
                address: {
                    line1: document.getElementById('billing_address_address').value,
                    city: document.getElementById('billing_address_city').value,
                    postal_code: document.getElementById('billing_address_zip').value,
                    country: document.getElementById('country_alpha_2').value
                }
            };
            stripe.createPaymentMethod('card', card, {billing_details}).then(function (result) {
                if (result.error) {
                    $('#card-errors').text(result.error.message);
                    $('button[type=submit]').attr('disabled', false);
                } else {
                    $('#confirmation-modal').modal('show');
                    $('#checkout-payment-method-id').val(result.paymentMethod.id);
                    submitCheckoutForm(result);
                }
            });
            return false;
        });
    }
});

function submitRequestCheckoutForm() {
    var $form = $('#process_order');
    $.ajax(
        {
            url: '/carts/confirm_shop_request',
            type: 'POST',
            data: $form.serialize(),
            dataType: 'json',
            success: function (data) {
                submitFinalForm();
            },
            error: function (xhr, evt, status) {
                var errors = $.parseJSON(xhr.responseText).errors;
                $('<div class="mt-4 alert alert-danger" role="alert" id="checkout-validation-warning">' + window.checkoutMessages.unsuccessfulOrder + ' ' + window.checkoutMessages.requiredFields + '<ul></ul></div>').insertBefore('#checkout-section');
                for (_i = 0, _len = errors.length; _i < _len; _i++) {
                    var value = errors[_i];
                    $('#checkout-validation-warning').find('ul').append('<li>' + value + '</li>');
                }
                shop.base.scrollTop("#checkout-wrapper");
                $('#confirmation-modal').modal('hide');
            }
        });
}

function submitCheckoutForm(result) {
    var $form = $('#process_order');
    $.ajax(
        {
            url: '/carts/confirm',
            type: 'POST',
            data: $form.serialize(),
            dataType: 'json',
            success: function (data) {
                $.ajax(
                    {
                        url: '/carts/confirm_shop_order',
                        type: 'POST',
                        data: {payment_method_id: result.paymentMethod.id},
                        dataType: 'json',
                        success: function (data) {
                            handleShopOrderServerResponse(data, result.paymentMethod.id);
                        }
                    });
            },
            error: function (xhr, evt, status) {
                var errors = $.parseJSON(xhr.responseText).errors;
                if (errors.length === 1) {
                    $('<div class="mt-4 alert alert-danger" role="alert" id="checkout-validation-warning">' + window.checkoutMessages.unsuccessfulOrder + ' <b>' + errors[0] + '</b></div>').insertBefore('#checkout-section');
                } else {
                    $('<div class="mt-4 alert alert-danger" role="alert" id="checkout-validation-warning">' + window.checkoutMessages.unsuccessfulOrder + ' ' + window.checkoutMessages.requiredFields + '<ul></ul></div>').insertBefore('#checkout-section');
                    for (_i = 0, _len = errors.length; _i < _len; _i++) {
                        var value = errors[_i];
                        $('#checkout-validation-warning').find('ul').append('<li>' + value + '</li>');
                    }
                }
                shop.base.scrollTop("#checkout-wrapper");
                $('#complete-purchase-button').attr('disabled', false);
                $('#confirmation-modal').modal('hide');
            }
        });
}

function handleShopOrderServerResponse(response, paymentMethodId) {
    var stripe = Stripe($('meta[name="stripe-key"]').attr('content'));
    if (response.requires_action) {
        stripe.handleCardAction(
            response.payment_intent_client_secret
        ).then(function (result) {
            if (result.error) {
                failed3dSecureShop(result.error.payment_intent.id);
            } else {
                $.ajax(
                    {
                        url: '/carts/confirm_shop_order',
                        type: 'POST',
                        data: {payment_intent_id: result.paymentIntent.id},
                        dataType: 'json',
                        success: function (data) {
                            handleShopOrderServerResponse(data, paymentMethodId);
                        }
                    });
            }
        });
    } else {
        submitFinalForm();
    }
}

function failed3dSecureShop(intentId) {
    $.ajax(
        {
            url: '/carts/failed_shop_3d_secure',
            type: 'POST',
            data: {payment_intent_id: intentId},
            dataType: 'json',
            success: function (data) {
                submitFinalForm();
            },
        });
}

function submitFinalForm() {
    updateCompleteOrderUrl();
    shop.base.disableCloseTabPrompt();
    $('#process_order')[0].submit();
}

function makePaymentRequest(paymentRequest) {
    paymentRequest.canMakePayment().then(function (result) {
        if (result) {
            $.ajax(
                {
                    url: '/carts/create_shop_request',
                    type: 'POST',
                    data: {
                        locale: $('meta[name="current-formatted-locale"]').attr('content'),
                        currency: $('meta[name="current-currency"]').attr('content')
                    },
                    dataType: 'json',
                    success: function (data) {
                        $('#google-request-button').show();
                        if (result.applePay === true) {
                            $('#apple-request-button').show();
                        }
                        $('#payment-methods-wrapper').attr('data-payment-intent-id', data.payment_intent.id)
                        shop.checkout.loadPaymentRequest(paymentRequest);
                        paymentRequest.on('paymentmethod', function (ev) {
                            $('#checkout-validation-warning').remove();
                            stripe.confirmCardPayment(
                                data.payment_intent.client_secret,
                                {payment_method: ev.paymentMethod.id},
                                {handleActions: false}
                            ).then(function (confirmResult) {
                                if (confirmResult.error) {
                                    ev.complete('fail');
                                    $('<div class="mt-4 alert alert-danger" role="alert" id="checkout-validation-warning">' + window.checkoutMessages.unsuccessfulOrder + ' <b>' + confirmResult.error.message + '</b></div>').insertBefore('#checkout-section');

                                    shop.base.scrollTop("#checkout-wrapper");
                                } else {
                                    ev.complete('success');
                                    stripe.confirmCardPayment(data.payment_intent.client_secret).then(function (result) {
                                        if (result.error) {
                                            $('<div class="mt-4 alert alert-danger" role="alert" id="checkout-validation-warning">' + window.checkoutMessages.unsuccessfulOrder + ' <b>' + result.error.message + '</b></div>').insertBefore('#checkout-section');
                                            shop.base.scrollTop("#checkout-wrapper");
                                        } else {
                                            $('#confirmation-modal').modal('show');
                                            $('#checkout-payment-intent-id').val(result.paymentIntent.id);
                                            submitRequestCheckoutForm();
                                        }
                                    });
                                }
                            });
                        });
                    },
                    error: function (xhr, evt, status) {
                        $('#card-payment-wrapper').show();
                    }
                });
        }
    });
}

function updateCompleteOrderUrl() {
    var completeOrderUrl = shop.base.checkoutGaUrl();
    $('#complete_order_url').val(completeOrderUrl);
}