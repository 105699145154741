shop.abandoned_cart =
    {
        updateEmail: function () {
            var textInput = document.getElementById('user_email'),
                timeout = null;

            if (typeof (textInput) != 'undefined' && textInput != null) {
                textInput.onkeyup = function (e) {

                    clearTimeout(timeout);

                    timeout = setTimeout(function () {
                        $.ajax(
                            {
                                url: '/carts/abandoned_cart_email?email=' + textInput.value,
                                type: 'POST',
                                dataType: 'json',
                                success: function (data) {
                                }
                            });
                    }, 500);
                };
            }
        }
    }