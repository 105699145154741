window.jQuery = $;
window.$ = $;

require("@rails/ujs").start();

// require("isotope-layout/dist/isotope.pkgd");

require("shop/shop.stripe-checkout");

require("shop/_shop");
require("shop/shop.base");
require("shop/shop.checkout");
require("shop/shop.product");
require("shop/shop.cart");
require("shop/shop.discount");
require("shop/shop.abandoned_cart");
require("shop/shop.review");
require("shop/shop.landing_page");
require("shop/shop.customer_gift_email");
require("shop/shop.ready");