shop.cart =
    {
        addCartItem: function () {
            $('body').on('submit', '#new_cart_item', function () {
                var url = $(this).attr('action'),
                    cartModal = $('#myCart');

                $('.add-to-cart-flash').remove();
                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $(this).serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#cart-subtotal span:last-child').text(data.subtotal);
                            $('.cart-item-count').text(data.cart_item_count);
                            var cartItem = $('ul#cart-item-list li#cart-item-' + data.cart_item_id);
                            if (cartItem.length > 0) {
                                cartItem.replaceWith(data.cart_item);
                            } else {
                                $('ul#cart-item-list').append(data.cart_item);
                            }
                            if (!cartModal.hasClass('show')) {
                                cartModal.modal('show');
                            }
                            $('#myCart .modal-body').prepend('<div class="alert alert-success add-to-cart-flash" role="alert"><i class="fa fa-check"></i> ' + window.cartMessages.addedCartItem + '</div>');
                            dataLayer.push({
                                event: 'eec.add',
                                ecommerce: {
                                    add: {
                                        actionField: {
                                            list: 'Shop Cart'
                                        },
                                        products: [
                                            {
                                                id: data.product_id,
                                                name: data.product_name,
                                                category: 'guides/google-tag-manager/enhanced-ecommerce',
                                                variant: data.variant_name,
                                                brand: 'Protecify',
                                                quantity: data.cart_item_count,
                                                dimension3: 'Ecommerce',
                                                metric5: 12,
                                                metric6: 1002
                                            }
                                        ]
                                    }
                                }
                            });
                        },
                        error: function (xhr, evt, status) {
                            if (!cartModal.hasClass('show')) {
                                cartModal.modal('show');
                            }
                            $('#myCart .modal-body').prepend('<div class="alert alert-danger add-to-cart-flash" role="alert">' + window.cartMessages.failedAddedCartItem + '</div>');
                        }
                    });
                return false;
            });
        },

        removeCartItem: function () {
            $('body').on('click', '.remove-cart-item', function () {
                var $this = $(this),
                    cartId = $('ul#cart-item-list').attr('data-cart-id'),
                    cartItemId = $this.attr('data-cart-item-id');

                $('.add-to-cart-flash').remove();
                $.ajax(
                    {
                        url: '/carts/' + cartId + '/cart_items/' + cartItemId,
                        type: 'DELETE',
                        dataType: 'json',
                        success: function (data) {
                            $('ul#cart-item-list li#cart-item-' + cartItemId).remove();
                            $('#cart-subtotal span:last-child').text(data.subtotal);
                            $('.cart-item-count').text(data.total_quantity);
                            $('#myCart .modal-body').prepend('<div class="alert alert-success add-to-cart-flash" role="alert">' + window.cartMessages.removedCartItem + '</div>');
                            dataLayer.push({
                                event: 'eec.remove',
                                ecommerce: {
                                    add: {
                                        actionField: {
                                            list: 'Shop Cart'
                                        },
                                        products: [
                                            {
                                                id: data.product_id,
                                                name: data.product_name,
                                                category: 'guides/google-tag-manager/enhanced-ecommerce',
                                                variant: data.variant_name,
                                                brand: 'Protecify',
                                                quantity: data.total_quantity,
                                                dimension3: 'Ecommerce',
                                                metric5: 12,
                                                metric6: 1002
                                            }
                                        ]
                                    }
                                }
                            });
                        },
                        error: function (xhr, evt, status) {
                            $('#myCart .modal-body').prepend('<div class="alert alert-danger add-to-cart-flash" role="alert">' + window.cartMessages.failedRemovedCartItem + '</div>');
                        }
                    });
                return false;
            });
        },

        increaseCartItemQuantity: function () {
            $('body').on('click', '.increase-quantity', function () {
                var $this = $(this),
                    cartId = $('ul#cart-item-list').attr('data-cart-id'),
                    cartItemId = $this.attr('data-cart-item-id');

                $('.add-to-cart-flash').remove();
                $.ajax(
                    {
                        url: '/carts/' + cartId + '/cart_items/' + cartItemId + '/increase_quantity',
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            $('ul#cart-item-list li#cart-item-' + cartItemId + ' .quantity').val(data.quantity);
                            $('ul#cart-item-list li#cart-item-' + cartItemId + ' .product-price').text(data.total_price);
                            $('#cart-subtotal span:last-child').text(data.subtotal);
                            $('.cart-item-count').text(data.total_quantity);
                            $('#myCart .modal-body').prepend('<div class="alert alert-success add-to-cart-flash" role="alert">' + window.cartMessages.updatedQuantity + '</div>');
                        },
                        error: function (xhr, evt, status) {
                            $('#myCart .modal-body').prepend('<div class="alert alert-danger add-to-cart-flash" role="alert">' + window.cartMessages.failedUpdatedQuantity + '</div>');
                        }
                    });
                return false;
            });
        },

        decreaseCartItemQuantity: function () {
            $('body').on('click', '.decrease-quantity', function () {
                var $this = $(this),
                    cartId = $('ul#cart-item-list').attr('data-cart-id'),
                    cartItemId = $this.attr('data-cart-item-id');

                $('.add-to-cart-flash').remove();
                $.ajax(
                    {
                        url: '/carts/' + cartId + '/cart_items/' + cartItemId + '/decrease_quantity',
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            $('ul#cart-item-list li#cart-item-' + cartItemId + ' .quantity').val(data.quantity);
                            $('ul#cart-item-list li#cart-item-' + cartItemId + ' .product-price').text(data.total_price);
                            $('#cart-subtotal span:last-child').text(data.subtotal);
                            $('.cart-item-count').text(data.total_quantity);
                            $('#myCart .modal-body').prepend('<div class="alert alert-success add-to-cart-flash" role="alert">' + window.cartMessages.updatedQuantity + '</div>');
                        },
                        error: function (xhr, evt, status) {
                            $('#myCart .modal-body').prepend('<div class="alert alert-danger add-to-cart-flash" role="alert">' + window.cartMessages.failedUpdatedQuantity + '</div>');
                        }
                    });
                return false;
            });
        },

        buildOnPageLoad: function () {
            shop.cart.loadCart();
        },

        loadCart: function () {
            var cartId = $('#cart-item-list').attr('data-cart-id'),
                currentLocale = $('meta[name="current-locale"]').attr('content');

            $.ajax(
                {
                    url: '/carts/' + cartId + '?locale=' + currentLocale,
                    type: 'GET',
                    dataType: 'json',
                    success: function (data) {
                        $('.cart-item-count').text(data.cart_item_count);
                        $('#cart-item-list').html(data.cart_items);
                        $('#cart-subtotal span:last-child').text(data.subtotal);
                    }
                });
        }
    }