shop.customer_gift_email =
    {
        create: function()
        {
            $('body').on('submit', '#new_customer_gift_email', function () {
                var url = $(this).attr('action');

                $('.customer-gift-email-flash').remove();
                $("#customer-gift-modal").modal('show');
                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $(this).serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $("#customer-gift-modal").modal('hide');
                            $('#customer-gift-email-wrapper').replaceWith('<p>Successfully sent the emails to your friends!</p>');
                        },
                        error: function (xhr, evt, status) {
                            $("#customer-gift-modal").modal('hide');
                            shop.base.addCustomFlash("#complete-order-container section", 'danger customer-gift-email-flash', 'Unable to send the emails, please make sure you fill both fields');
                        }
                    });
                return false;
            });
        }
    }