shop.product = {

    incrementQuantity: function () {
        $elem = $('#cart_item_quantity');
        newValue = Number($elem.val()) + 1;
        $elem.val(newValue);
    },

    decreaseQuantity: function () {
        $elem = $('#cart_item_quantity');
        newValue = Number($elem.val()) - 1;
        if (newValue < 1) {
            $elem.val(1);
        } else {
            $elem.val(newValue);
        }
    },

    changeVariant: function () {
        $('body').on('change', '.variant-dropdown', function () {
            var productId = $(this).attr('data-product-id'),
                variantId = $(this).val();
            $.ajax(
                {
                    url: '/products/' + productId + '/variants/' + variantId,
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        $('#product-wrapper-' + productId + ' .product-price').text(data.variant_price);
                        $('#product-wrapper-' + productId + ' .product-compare-price').text(data.variant_compare_price);
                        if (data.in_stock) {
                            $('#product-wrapper-' + productId + ' .product-add-to-cart').show();
                            $('#product-wrapper-' + productId + ' .product-checkout-now').show();
                            $('#product-wrapper-' + productId + ' .product-no-stock-text').text('');
                            $('#product-wrapper-' + productId + ' .product-no-stock-text').hide();
                        } else {
                            $('#product-wrapper-' + productId + ' .product-add-to-cart').hide();
                            $('#product-wrapper-' + productId + ' .product-checkout-now').hide();
                            $('#product-wrapper-' + productId + ' .product-no-stock-text').text(data.stock_text);
                            $('#product-wrapper-' + productId + ' .product-no-stock-text').show();
                        }
                        if (data.zero_price) {
                            $('#product-wrapper-' + productId + ' .product-add-to-cart').hide();
                            $('#product-wrapper-' + productId + ' .product-checkout-now').hide();
                        } else {
                            $('#product-wrapper-' + productId + ' .product-add-to-cart').show();
                            $('#product-wrapper-' + productId + ' .product-checkout-now').show();
                        }
                        if (data.product_image_id !== null) {
                            var index = $('.product-carousel-' + productId + ' .carousel-item[data-image-id="' + data.product_image_id + '"]').attr('data-index');
                            $('.product-carousel-' + productId).carousel(Number(index));
                        }
                    }
                });
        });
    },

    initCarousel: function () {
        $('#productCarousel').carousel({
            interval: false,
        });
    },

    tileTabs: function () {
        $('body').on('click', '.tile-nav-link', function () {
            var tabId = $(this).attr('data-tab-id');

            if ($(this).hasClass('active')) {
                $(this).removeClass('active').attr('aria-selected', false);
                $('.tile-tab-content#' + tabId).removeClass('show').removeClass('active');
            } else {
                $('.tile-tab-content').removeClass('show').removeClass('active');
                $('.tile-nav-link').removeClass('active').attr('aria-selected', false);
                $(this).addClass('active').attr('aria-selected', true);
                $('.tile-tab-content#' + tabId).addClass('show').addClass('active');
            }

            return false;
        });
    },

    initCountdown: function (element) {
        "undefined" != typeof b && clearInterval(b);
        var b = setInterval(function () {
            var elem = $(element),
                endTime = elem.attr('data-end-at');


            const hoursSpan = elem.find('.hours');
            const minutesSpan = elem.find('.minutes');
            const secondsSpan = elem.find('.seconds');

            var t = shop.checkout.getTimeRemaining(endTime);

            hoursSpan.html(('0' + t.hours).slice(-2));
            minutesSpan.html(('0' + t.minutes).slice(-2));
            secondsSpan.html(('0' + t.seconds).slice(-2));
        }, 1e3);
    },

    triggerEmailReview: function () {
        if (shop.base.getUrlVars()['email_review'] !== undefined) {
            // if ($('#review-product').length > 0) {
            var productId = $('.product-wrapper').attr('data-product-id');
            $.ajax(
                {
                    url: '/products/' + productId + '/reviews/new',
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        $('#product-review-modal').html(data.modal);
                        shop.base.openModal('#product-review-form');
                        var $rateYo = $("#star-rating-animation").rateYo(
                            {
                                fullStar: true,
                                rating: 5
                            });
                        $rateYo.on("rateyo.set", function (e, data) {
                            document.getElementById("review_rating").value = data.rating;
                        });
                    },
                    error: function (xhr, evt, status) {
                        shop.base.addCustomFlash(".product-wrapper", 'danger product-review-flash', 'Unable to add a review at the moment, please try again later');
                        shop.base.scrollTop('.navbar-container');
                    }
                });
            // } else {
            //     if ($('meta[name="user-signed-in"]').attr('content') === 'true') {
            //         shop.base.addCustomFlash(".product-wrapper", 'success', 'You have already left a review for this product.');
            //         shop.base.scrollTop('.navbar-container');
            //     } else {
            //         shop.base.addCustomFlash(".product-wrapper", 'success', 'Please login to review this product.');
            //         $("#loginUserReview").modal('show');
            //         shop.base.scrollTop('.navbar-container');
            //     }
            // }
        }
    },

    triggerReviewLogin: function () {
        $('body').on('click', '#customer-review-product', function () {
            $('#loginUserReview').modal('show');

            return false;
        });
    },

    loginReview: function () {
        $("body").on("submit", '#loginUserReview #new_user', function () {
            var url = $(this).attr('action');

            $.ajax(
                {
                    url: url,
                    type: 'POST',
                    data: $(this).serialize(),
                    dataType: 'json',
                    success: function (data) {
                        $('#loginUserReview').modal('hide');
                        window.location = data.redirect_url;
                    },
                    error: function (xhr, evt, status) {
                        $('#loginUserReview .json-error-wrapper #errors ul').html("<li><i class='fa fa-times-circle'></i>Incorrect login details</li>");
                        $('#loginUserReview .json-error-wrapper').show();
                    }
                });
            return false;
        });
    },
}