$(document).ready(function () {

    shop.base.changeLocale();
    shop.base.initCookies();
    shop.base.selectCheckboxLabel();
    // shop.base.sendNewsletterEmail();

    shop.product.changeVariant();
    shop.product.initCarousel();
    shop.product.tileTabs();
    shop.product.loginReview();
    shop.product.triggerReviewLogin();

    shop.cart.buildOnPageLoad();
    shop.cart.addCartItem();
    shop.cart.removeCartItem();
    shop.cart.increaseCartItemQuantity();
    shop.cart.decreaseCartItemQuantity();

    shop.checkout.confirmEmail();
    shop.checkout.duplicateAddress();
    shop.checkout.validShippingAddress();
    shop.checkout.selectPaymentMethod();
    shop.checkout.validateReferralCode();
    shop.checkout.addOrderBumpToCart();
    shop.checkout.removeOrderBumpToCart();
    shop.checkout.changeOrderBumpVariant();
    shop.checkout.updateCountryAlpha2();

    shop.abandoned_cart.updateEmail();

    shop.discount.addToCart();

    shop.review.add();
    shop.review.create();

    shop.landing_page.incrementQuantity();
    shop.landing_page.decreaseQuantity();
    shop.landing_page.initCarousel();
    shop.landing_page.changeVariant();
    shop.landing_page.cartDropdown();

    shop.customer_gift_email.create();
});